var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CASH_REGISTERS) && !_vm.hideOrganization
    )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CASH_REGISTER')}`,"placeholder":_vm.$t('COMMON.CASH_REGISTER')}},[_c('cash-register-selector',{attrs:{"filterable":true,"showAll":false,"disabled":!!_vm.cashRegisterReading.id},on:{"cashRegisterChanged":(cashRegisterId, cashRegister) => {
          _vm.cashRegisterReading.cashRegister.id = cashRegisterId;
          _vm.cashRegisterReading.organization.id = cashRegister.organization.id;
          _vm.cashRegisterReading.establishment = cashRegister.establishment;
          _vm.cashRegisterReading.serviceCenter = cashRegister.serviceCenter;
          _vm.cashRegisterReading.cafeteria = cashRegister.cafeteria;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cashRegister}}),_c('base-input',{attrs:{"label":`${_vm.$t('CASH_REGISTER_READINGS.NUMBER_Z')} (*)`,"required":true,"placeholder":_vm.$t('CASH_REGISTER_READINGS.NUMBER_Z')},on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.cashRegisterReading.number_z),callback:function ($$v) {_vm.$set(_vm.cashRegisterReading, "number_z", $$v)},expression:"cashRegisterReading.number_z"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cashier}}),_c('base-input',{attrs:{"label":"Caissiere *","required":true,"placeholder":"Caissiere"},on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.cashRegisterReading.cashier),callback:function ($$v) {_vm.$set(_vm.cashRegisterReading, "cashier", $$v)},expression:"cashRegisterReading.cashier"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cashier}}),_c('base-input',{attrs:{"label":`${_vm.$t('CASH_REGISTER_READINGS.STARTS_AT')} (*)`,"placeholder":_vm.$t('CASH_REGISTER_READINGS.STARTS_AT')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        allowInput: true,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        // minDate: 'today',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.cashRegisterReading.starts_at),callback:function ($$v) {_vm.$set(_vm.cashRegisterReading, "starts_at", $$v)},expression:"cashRegisterReading.starts_at"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.starts_at}}),_c('base-input',{attrs:{"label":`${_vm.$t('CASH_REGISTER_READINGS.ENDS_AT')} (*)`,"placeholder":_vm.$t('CASH_REGISTER_READINGS.ENDS_AT')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        // minDate: 'today',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.cashRegisterReading.ends_at),callback:function ($$v) {_vm.$set(_vm.cashRegisterReading, "ends_at", $$v)},expression:"cashRegisterReading.ends_at"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.ends_at}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.cashRegisterReading.id ? _vm.$t("CASH_REGISTER_READINGS.EDIT_CASH_REGISTER_READING") : _vm.$t("CASH_REGISTER_READINGS.ADD_CASH_REGISTER_READING"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }